// 关闭webview
export const closeAll = (system) => {
    const message = {
        'methodName': 'closeWebView',
    }
    try {
        if (system === "ios") {
            window.webkit.messageHandlers.baseMessagehandler.postMessage(message)
        }
        if (system === 'android') {
            window.WebViewJavascriptBridge.send('closeWebView')
            window.WebViewJavascriptBridge.send('disableUserFinishFalse')
        }
        if(system.startsWith('flutter')){
            window.messageHandler.postMessage(JSON.stringify(message))
        }
    } catch (e) {
        console.log('closeAll error', e)
    }
}

// 支付成功时回调
export const sendSuccessMsg = (system,data) => {
    try {
        if (system === 'ios') {
            const message = {
                'methodName': 'invokePaySuccessFunction',
                params: data,
                'callbackMethod': ''
            }
            window.webkit.messageHandlers.PaySuccessHandler.postMessage(message)
        }
        if (system === 'android') {
            console.log('call android  payResult')
            window.WebViewJavascriptBridge.callHandler('payResult', '0');
        }
        if(system.startsWith('flutter')){
            window.messageHandler.postMessage(JSON.stringify( {methodName: 'invokePaySuccessFunction', params: data}))
        }
    } catch (e) {
        console.log('sendSuccessMsg error', e)
    }
}
// 支付失败时回调
export const sendFailedMsg = (system,errorcode) => {
    try {
        if (system === 'android') {
            window.WebViewJavascriptBridge.send('disableUserFinishFalse')
            window.WebViewJavascriptBridge.callHandler('payResult', errorcode);
        }
        if(system.startsWith('flutter')){
            window.messageHandler.postMessage(JSON.stringify( {methodName: 'invokePayFailedFunction'}))
        }
    } catch (e) {
        console.log('sendFailedMsg error', e)
    }
}
// 原生支付
export const makeNativePay = (system) => {
    try {
        if (system === 'ios') {
            const message = {
                'methodName': 'invokeAppleIapFunction',
                'params': '',
                'callbackMethod': '' // 待定
            };
            window.webkit.messageHandlers.AppIapHandler.postMessage(message);
        }
        if (system === 'android') {
            window.WebViewJavascriptBridge.send('nativePay')
        }
        if(system.startsWith('flutter')){
            window.messageHandler.postMessage(JSON.stringify( {methodName: 'invokeAppleIapFunction'}))
        }
    } catch (e) {
        console.log('makeNativePay error', e)
    }
}
//打开链接
export const openBrowser =(system,url,storePackageName,cb)=>{
    try {
        if (system === 'android') {
            window.WebViewJavascriptBridge
                .callHandler('openStore',
                    {storePackageName, url},
                    null
                )
            cb&&cb()
        }
        if (system === 'ios') {
            const msg = {
                methodName: 'invokeOpenAppStore',
                params: {
                    appStoreUrl: url
                },
                callbackMethod: ''
            }
            window.webkit.messageHandlers.CallAppStoreMessagehandler.postMessage(msg)
        }
        if(system.startsWith('flutter')){
            window.messageHandler.postMessage(JSON.stringify( {methodName: 'invokeOpenAppStore',url}))
        }
    } catch (e) {
        console.log('openURL error', url, e)
        cb&&cb()
    }
}

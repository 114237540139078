import accounting from "accounting";

export const registerBridge = (cb)=>{
    window.WebViewJavascriptBridge.send('disableUserFinishTrue')
    window.WebViewJavascriptBridge.registerHandler('configureOrderInfo', function(data, responseCallback) {
        cb&&cb(data)
        responseCallback('success')
    })
}
export const getNumberOfPrice = (str)=>{
    const reg = /[0-9]+([.,][0-9]{1,3})*/g
    const numOfPrice = reg.exec(str.replace(/\s*/g,""))[0].split('')
    console.log('configureOrderInfo -> numOfPrice', numOfPrice)
    // eslint-disable-next-line no-unused-vars
    let thousand = '' // 千位分隔符
    let decimal = '' // 小数点
    for(let i = numOfPrice.length-1;i>=0;i--){
        const word = numOfPrice[i]
        if(['.',','].includes(word)){
            console.log('configureOrderInfo -> word', word)
            const d = numOfPrice.length-1-i
            console.log('configureOrderInfo -> d', d)
            if(word === '.'){
                if(d<=2){
                    decimal='.'
                    thousand=','
                } else {
                    decimal=','
                    thousand='.'
                }
            }else{
                if(d<=2){
                    decimal=','
                    thousand='.'
                } else {
                    decimal='.'
                    thousand=','
                }
            }
            break
        }
    }
    return accounting.unformat(numOfPrice.join(''), decimal)
}
export const getCountryCode = ()=>{
    const language =  window.navigator.language
    const arr = language.toLowerCase().split('-')
    const firstCode = arr.slice(0,1)[0]
    const lastCode = arr.slice(-1)[0]
    if(firstCode === 'zh'){
        if(lastCode==='sg'){
            return 'SG'
        }
        return 'CN'
    }
    if(firstCode === 'gsw'){
        return 'FR'
    }
    if(lastCode === 'latn'){
        return 'ES'
    }
    if(['az-Cyrl','az-Latn','az-Latn-AZ','az-Cyrl-AZ'].includes(language)){
        return 'AZ'
    }
    if(['bs-Latn','bs-Cyrl','bs-Latn-BS','bs-Cyrl-BS'].includes(language)){
        return 'FR'
    }
    if(['ha-Latn','ha-Latn-NG'].includes(language)){
        return 'NG'
    }
    if(firstCode==='mk'){
        return 'MK'
    }
    if(['sr-Latn','sr-Cyrl'].includes(language)){
        return 'RS'
    }
    if(language === 'tg-Cyrl'){
        return 'TJ'
    }
    if(language === 'tzm-Latn'){
        return 'DZ'
    }
    if(['iu-Latn','iu-Cans'].includes(language)){
        return 'CA'
    }
    if(language === 'en-029'){
        return 'CU'
    }
    return lastCode.toUpperCase()
}
export const paypalAllowedCurrency = ['aud','brl','cad','czk','dkk','eur',
    'hkd','huf','ils','jpy','mxn','twd','nzd','nok','php',
    'pln','gbp','rub','sgd','sek','chf','thb','usd']
export const parseUserLevel = (cfgObj,levelNum,system)=>{
    let levelScheme = {}
    if(system.includes('ios')){
        const obj = cfgObj.iosConfig.cfg_daoliang_level_scheme
        levelScheme = JSON.parse(obj).home_page
    } else {
        const obj = cfgObj.androidConfig.cfg_daoliang_level_scheme
        levelScheme = JSON.parse(obj).home_page
    }
    if(Object.keys(levelScheme).length === 0){
        levelScheme = []
    }
    let defaultLevel = {}
    if(system.includes('ios')){
        defaultLevel = levelScheme.find(e => e.level === 'default')
    } else {
        defaultLevel = levelScheme.find(e => e.level+'' === '0')
    }
    const oneLevel = levelScheme.find(e => e.level + '' === levelNum + '')
    if(oneLevel){
        return oneLevel
    } else {
        if(defaultLevel){
            return defaultLevel
        } else {
            return null
        }
    }
}
export const defaultDownloadIcon = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAB30lEQVRYR+2XsUscQRTGvzd3xUGaQCqbRFuFtLYRQUKqhBBLEYQ0hrPZeYOV3Z3z5lAkkMbCIpVgYyDgVblKlECKkEuXIn9GmjxZuYXl0Nu5rHenkq12Z78332/nvX3DECZ80YT9QSGELVV9A2BuzDAdIuqQiHwB8GzM5pldNwXQ9ImZx5qOzPd+ArRarVlV3VHVh0S0Ya0970/xyFYghDCvqmd5Q2PMXJIkP/NjIwMQkdR8Pm9GRG1r7fO7DSAirwE8ZubdQb/syFIgIt8BPDHGrCVJcjQI4saL0Hu/SUSNnmkXwAozfyvTvKKLsNlsTlcqlR8AHuQMT2q12st6vf7nXyGiAUTkAMBqv5Gq7jvn3g4DEELYs9ZupDFRACKyBKA9wGSTmbdjIETkM4AXWcuPBSjaqP6q6ivn3KfrIHopPAbwNL/nFAJ4798R0fuIr/sNYJGZf/VrvfeLxpiPqjqVvYtagUaj8aharX4FMBMBkEo6zLzQ1+nSutkHUM2PRwGEEERVbaT5pUxVPzjn1tN7770lIrkqvhCg18lOAZhhAGK1MQCHqrocO+GwukKArDqHnThW/x/g9q9AbC7L6go7YVmDovg8QFG/L5qrzPvu5I9mZfBvInasx7GrgC8A5ZdeDP4fB5IAAAAASUVORK5CYII='

//获取qs
export const getURLParams = (url) => {
    if(!url){
        url = location.href
    }
    const q = {}
    url.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => {
        q[k] = v
    })
    return q
}

import axios from 'axios'
import axiosRetry from 'axios-retry'
import to from 'await-to-js'
const notUndefined = (obj) => {
    return typeof obj !== 'undefined' && obj !== null
}
const Service = axios.create({
    baseURL:'https://api.solotech.me',
    timeout: 12 * 1000, // 请求超时时间
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})
const ServiceWithoutRetry = axios.create({
    baseURL:'https://api.solotech.me',
    timeout: 12 * 1000, // 请求超时时间
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})
// 请求拦截器
Service.interceptors.response.use(
  response => {
      return response.data
  },
  error => {
      console.log('error', error.response)
      return Promise.reject(error)
  }
)
ServiceWithoutRetry.interceptors.response.use(
  response => {
      return response.data
  },
  error => {
      console.log('error', error)
      return Promise.reject(error)
  }
)
axiosRetry(Service, {
    retries: 2,
    retryDelay: delayCount => delayCount * 100
})

export async function _get(url, qs,headers,cb) {
    const params = {
        url,
        method: 'get',
        params: notUndefined(qs) ? qs : ''
    }
    if(notUndefined(headers)){params.headers = headers}
    const [err, res] = await to(Service(params))
    if (!err && res) {
        return res
    } else {
        cb&&cb()
        console.log(err)
        return false
    }
}
export async function _getNoRetry(url, qs,headers,cb) {
    const params = {
        url,
        method: 'get',
        params: notUndefined(qs) ? qs : ''
    }
    if(notUndefined(headers)){params.headers = headers}
    const [err, res] = await to(ServiceWithoutRetry(params))
    if (!err && res) {
        return res
    } else {
        cb&&cb()
        console.log(err)
        return false
    }
}
export async function _post(url, qs, body) {
    const params = {
        url,
        method: 'post',
        params: notUndefined(qs) ? qs : {},
        data: notUndefined(body) ? body : {}
    }
    const [err, res] = await to(Service(params))
    if (!err && res) {
        return res
    } else {
        console.log(err)
        return false
    }
}
export async function _postWithoutRetry(url, qs, body) {
    const params = {
        url,
        method: 'post',
        params: notUndefined(qs) ? qs : {},
        data: notUndefined(body) ? body : {}
    }
    const [err, res] = await to(Service(params))
    if (!err && res) {
        return res
    } else {
        console.log(err)
        return false
    }
}
export async function _put(url, qs, body) {
    const params = {
        url,
        method: 'put',
        params: notUndefined(qs) ? qs : {},
        data: notUndefined(body) ? body : {}
    }
    const [err, res] = await to(Service(params))
    if (!err && res) {
        return res
    } else {
        console.log(err)
        return false
    }
}
export async function _delete(url, qs) {
    const params = {
        url,
        method: 'delete',
        params: notUndefined(qs) ? qs : ''
    }
    const [err, res] = await to(Service(params))
    if (!err && res) {
        return res
    } else {
        console.log(err)
        return false
    }
}
export default Service
